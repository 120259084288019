import React from "react"
// import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, author, lang, seoDescription }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
          }
        }
      }
    `}
    render={data => {
      const { siteMetadata } = data.site
      const metaDescription = description || siteMetadata.description
      const titleFormat = title
        ? `${title} | ${siteMetadata.title}`
        : `${siteMetadata.title} | ${siteMetadata.description}`

      return (
        <Helmet>
          <html lang={siteMetadata.lang} />
          <title>{titleFormat}</title>
          <meta name="description" content={metaDescription} />
          <meta http-equiv="Content-Language" content={siteMetadata.lang} />
        </Helmet>
      )
    }}
  />
)

export default SEO
