import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import CSS from "../config/css"
import SEO from "../components/seo"

import { Row } from "./row"
import Menu from "./menu"

// Images
import iconFacebook from "../images/ui/icon-facebook.svg"
import iconInstagram from "../images/ui/icon-instagram.svg"
import logoFull from "../images/ui/logo-frosted-full.svg"
import logo from "../images/ui/logo-frosted.svg"

const currentYear = new Date().getFullYear()

const LayoutHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${CSS.gutter} auto;
  text-align: center;
  @media ${CSS.mq.medium} {
    flex-direction: row;
  }
`

const LayoutHeaderMenu = styled.div`
  margin-top: 0;
  text-align: center;
  @media ${CSS.mq.medium} {
    margin-top: ${CSS.margin};
    text-align: right;
  }
`

const LayoutHeaderContact = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;

  @media ${CSS.mq.small} {
    flex-direction: row;
    align-items: initial;
  }

  @media ${CSS.mq.medium} {
    justify-content: flex-end;
  }
`

const SocialLink = styled.a`
  display: inline-block;
  width: 24px;
  height: 24px;
`

const AlertBanner = styled.div`
  display: block;
  background-color: ${CSS.colors.gold};
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  a {
    display: inline-block;
    padding: 8px 8px 5px;
    border-bottom: 3px dotted ${CSS.colors.darkgrey};
    color: ${CSS.colors.darkgrey};
    font-weight: bold;
    text-decoration: none;
    &:hover {
      border-color: ${CSS.colors.darkgrey};
    }
  }
`

const SocialLinks = () => (
  <div>
    <SocialLink
      style={{ marginRight: "10px" }}
      href="https://instagram.com/frostedcakery"
    >
      <img src={iconInstagram} alt="Instagram" />
    </SocialLink>
    <SocialLink big href="https://facebook.com/frostedcakery">
      <img src={iconFacebook} alt="Facebook" />
    </SocialLink>
  </div>
)

export default ({ children }) => (
  <div>
    <SEO />
    <AlertBanner>
      Order online <a href="https://store.frostedcakery.com" rel="nofollow">shop.frostedcakery.com</a> or <a href="https://store.frostedcakery.com/s/appointments">book a consultation</a>
    </AlertBanner>
    <Row className="collapse-small">
      <LayoutHeader>
        <div>
          <h1
            style={{
              textAlign: "center",
              maxWidth: "162px",
              margin: "10px auto",
            }}
          >
            <Link to="/">
              <img src={logo} alt="Frosted Cakery" />
            </Link>
          </h1>
        </div>
        <LayoutHeaderMenu>
          <LayoutHeaderContact>
            <span>(559) 917-8880</span>
            <span>(559) 455-8979</span>
            <SocialLinks />
          </LayoutHeaderContact>
          <Menu />
        </LayoutHeaderMenu>
      </LayoutHeader>
    </Row>

    {children}

    <Row className="collapse-small">
      <footer style={{ marginTop: "60px", textAlign: "center" }}>
        <Menu />
        <p>
          <SocialLinks />
        </p>
        <p>
          <Link style={{ maxWidth: "200px", display: "inline-block" }} to="/">
            <img src={logoFull} alt="Frosted Cakery" />
          </Link>
        </p>
        <div style={{ fontSize: "80%", margin: "16px 0" }}>
          <address style={{ marginBottom: "8px" }}>
             1292 N. Wishon, Fresno, CA 93728 • (559) 917-8880
          </address>

          <address style={{ marginBottom: "8px" }}>
            2025 W. Bullard, Fresno, CA 93711 • (559) 455-8979
          </address>
          <Link to="/terms">Terms &amp; Conditions</Link> • Frosted Cakery ©{" "}
          {currentYear}
        </div>
      </footer>
    </Row>
  </div>
)
