import React from "react"
import styled, { css } from "styled-components"
import CSS from "../config/css"

import imageLineDiag from "../images/ui/line-diag.png"

const Row = styled.div`
  width: 100%;
  max-width: ${CSS.rowWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${CSS.margin};
  padding-right: ${CSS.margin};

  &.collapse-small {
    padding-right: 0;
    padding-left: 0;
    @media ${CSS.mq.medium} {
      padding-right: ${CSS.margin};
      padding-left: ${CSS.margin};
    }
  }
`

const Shim = styled.div`
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media ${CSS.mq.medium} {
        flex-direction: row;
      }
    `}
  margin-bottom: ${CSS.gutter};
  @media ${CSS.mq.medium} {
    padding-right: 90px;
    padding-left: 90px;
  }
`

const FullRow = styled.div`
  width: 100%;
  max-width: auto;
  padding: 0;
  ${props =>
    props.background &&
    css`
      background-image: linear-gradient(
          to bottom,
          ${props.background},
          ${props.background}
        )
        ${props =>
          props.background &&
          css`
  , url(${props.backgroundImage})
  `};
      background-size: cover;
      background-attachment: fixed;
    `}
  margin-bottom: 16px;
  padding: ${props => props.padding || "none"};
  ${Shim} {
    max-width: ${CSS.rowWidth};
    margin: 0 auto;
  }
`

const FlexItem = styled.div`
  flex: ${props => props.flex || "auto"};
  background-color: ${props => props.background || "white"};
  margin: 8px;
  padding: 0 ${CSS.margin};
  @media ${CSS.mq.medium} {
    margin: 0 8px;
  }
`

const PageTitle = styled.h2`
  margin-bottom: ${CSS.gutter};
  font-family: ${CSS.fonts.script};
  font-size: 60px;
  text-align: center;
`

export { Row, FullRow, Shim, PageTitle, FlexItem }
