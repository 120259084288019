const CSS = {
  margin: "16px",
  gutter: "32px",
  rowWidth: "1152px",
  colors: {
    gold: "#d4c799",
    goldrgb: "212, 199, 153",
    darkgold: "#b9a970",
    darkergold: "#aa9c6a",
    lightmint: "#cef6e4",
    mint: "#92d9ba",
    mintrgb: "146, 271, 186",
    darkmint: "#78c4a3",
    white: "#ffffff",
    whitergb: "255, 255, 255",
    grey: "#999999",
    darkgrey: "#333333",
    darkgreyrgb: "51, 51, 51",
    lightgrey: "#f7f7f7",
  },
  fonts: {
    script: "'Dancing Script', 'Brush Script MT', cursive",
    body: "'Raleway', 'Century Gothic', Helvetica, Arial, sans-serif",
  },
  mq: {
    xsmall: "only screen and (max-width: 370px)",
    small: "only screen and (min-width: 560px)",
    medium: "only screen and (min-width: 860px)",
    large: "only screen and (min-width: 1025px)",
    xlarge: "only screen and (min-width: 1920px)",
  },
}

export default CSS
