import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import CSS from "../config/css"

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: none;
  border-top: 1px solid ${CSS.colors.gold};
  list-style: none;
  @media ${CSS.mq.medium} {
    display: inline-block;
    margin-bottom: ${CSS.margin};
    border: none;
  }
`
const MenuItem = styled.li`
  display: flex;
  flex: 1 1 50%;
  border-bottom: 1px solid ${CSS.colors.gold};
  margin-bottom: 0;
  text-align: center;
  :nth-child(2n) {
    border-left: 1px solid ${CSS.colors.gold};
  }
  @media ${CSS.mq.medium} {
    display: inline-block;
    border: none;
    :nth-child(2n) {
      border: none;
    }
  }
`
const MenuLink = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${CSS.colors.darkgrey};
  @media ${CSS.mq.medium} {
    padding: 0 0 0 ${CSS.gutter};
  }
`

const MenuWrapper = styled.nav`
  display: block;
  margin: ${CSS.gutter} 0 ${CSS.margin} 0;
  @media ${CSS.mq.medium} {
    margin: 0;
  }
`

const Menu = props => (
  <MenuWrapper align={props.align}>
    <MenuList>
      <MenuItem>
        <MenuLink to="/cakes">Cakes</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink to="/cupcakes">Cupcakes</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink to="/desserts">Desserts</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink to="/menu">Menu</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink to="/about">About</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink to="/contact">Contact</MenuLink>
      </MenuItem>
    </MenuList>
  </MenuWrapper>
)

export default Menu
